import React from "react"
import PropTypes from "prop-types"
import { Container, Row, Col, Button } from "react-bootstrap"
import { Link, graphql } from "gatsby"
import kebabCase from "lodash/kebabCase"
import startCase from "lodash/startCase"
import capitalize from "lodash/capitalize"
import { GatsbyImage, getSrc, getImage } from "gatsby-plugin-image";
import { Disqus, CommentCount } from "gatsby-plugin-disqus"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { tagListContainer, tagList, tagListItem, headerText, seriesTag, postMetadata, postMetadataItem, postTag, blogPostContainer } from "../styles/blogPost.module.css"
import "../styles/blogPostContent.css"

const BlogPost = ({ data }) => {
  const { markdownRemark: post, site } = data
  const { tags } = data.totalCount
  var disqusConfig = {
    url: `${site.siteMetadata.siteUrl}${post.frontmatter.path}`,
    identifier: `${post.id}`,
    title: `${post.frontmatter.title}`,
  }

  function shuffle(array) {
    let currentIndex = array.length, randomIndex;

    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }
    return array;
  }

  return (
    <Layout>
      <Container fluid className="text-white">
        <Row className={[tagListContainer]}>
          <Col xs={{ span: 11, offset: 1 }} sm={{ span: 11, offset: 1 }} className={[tagList]}>
            {tags && shuffle(tags).slice(0, 10).map(({ fieldValue: tag }) => {
              return (
                <Button variant="outline-info" href={`/tags/${kebabCase(tag)}`} key={tag} className={[tagListItem]}>{startCase(tag)}</Button>
              )
            })}
            <Button variant="info" href="/tags" className={[tagListItem]}>Find all tags</Button>
          </Col>
        </Row>
        <Row className="justify-content-center mb-5">
          <Col lg={{ span: 6, offset: 1 }} md={{ span: 9, offset: 1 }} xs={{ span: 10, offset: 2 }} sm={{ span: 11, offset: 1 }}>
            <div className="blog-post-container">
              <div className="text-left">
                {post.frontmatter.banner &&
                  <GatsbyImage
                    image={getImage(post.frontmatter.banner)}
                    alt="Banner Image" />
                }
                <h1 className={headerText}>{post.frontmatter.title}</h1>
                {post.frontmatter.series.length > 0 &&
                  <h2 className={seriesTag}>
                    Series:
                    <Link to={`/series/${kebabCase(post.frontmatter.series)}`} className="ps-1">{post.frontmatter.series.replace(/\w+/g, capitalize)}</Link>
                  </h2>
                }
                <div className="position-relative">
                  <Row className={postMetadata}>
                    <Col xs={12} sm="auto" className="pe-0 ps-2">
                      <small className={postMetadataItem}>Created on {post.frontmatter.dateCreated}</small>
                    </Col>
                    {post.frontmatter.dateCreated !== post.frontmatter.dateEdited &&
                      <Col xs={12} sm="auto" className="pe-0 ps-2">
                        <small className={postMetadataItem}><em>Updated on {post.frontmatter.dateEdited}</em></small>
                      </Col>
                    }
                    <Col xs={12} sm="auto" className="pe-0 ps-2">
                      <small className={postMetadataItem}>{post.timeToRead} min read</small>
                    </Col>
                    <Col xs={12} sm="auto" className="pe-0 ps-2">
                      <small className={postMetadataItem}>
                        <CommentCount config={disqusConfig} placeholder={'...'} />
                      </small>
                    </Col>
                  </Row>
                </div>
                <Row>
                  <Col xs="auto" className="pe-0">
                    <small>Tags:</small>
                  </Col>
                  <Col className="p-0 w-auto">
                    {post.frontmatter.tags.length > 0
                      ? post.frontmatter.tags.map(tag => {
                        return (
                          <Button variant="link" href={`/tags/${kebabCase(tag)}`} key={tag} className={[postTag]}><small>{startCase(tag)}</small></Button>
                        )
                      })
                      : <small className="ps-1">uncategorized</small>
                    }
                  </Col>
                </Row>
                <hr />
                <div
                  className={blogPostContainer}
                  dangerouslySetInnerHTML={{ __html: post.html }}
                />
              </div>
            </div>
            <Disqus config={disqusConfig} />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

export const Head = (props) => {
  const imagePath = getSrc(props.data.markdownRemark.frontmatter.banner)

  const pageSeo = {
    title: props.data.markdownRemark.frontmatter.title,
    description: props.data.markdownRemark.frontmatter.description,
    pathname: props.location.pathname,
    image: imagePath
  }

  return (
    <Seo pageSeo={pageSeo}>
      <script type="application/ld+json" >
        {`
        {
          "@context": "https://schema.org/",
          "@type": "NewsArticle",
          "headline": "${pageSeo.title}",
          "image": [
            "${props.data.site.siteMetadata.siteUrl}${imagePath}"
          ],
          "datePublished": "${props.data.markdownRemark.frontmatter.dateCreated}",
          "dateModified": "${props.data.markdownRemark.frontmatter.dateEdited}",
          "author": [{
              "@type": "Person",
              "name": "${props.data.site.siteMetadata.author}",
              "url": "${props.data.site.siteMetadata.siteUrl}/about"
            }]
        }
      `}
      </script>
    </Seo>
  );
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      id: PropTypes.string.isRequired,
      html: PropTypes.string.isRequired,
      frontmatter: PropTypes.shape({
        dateCreated: PropTypes.string.isRequired,
        dateEdited: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        series: PropTypes.string.isRequired,
        tags: PropTypes.array.isRequired,
        banner: PropTypes.object,
      }).isRequired,
      timeToRead: PropTypes.number.isRequired,
    }).isRequired,
    totalCount: PropTypes.shape({
      tags: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired
        }).isRequired,
      )
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        defaultTitle: PropTypes.string.isRequired,
        titleTemplate: PropTypes.string.isRequired
      }).isRequired,
    })
  }).isRequired,
}

export default BlogPost

export const pageQuery = graphql`query BlogPostByPath($path: String!) {
  markdownRemark(frontmatter: {path: {eq: $path}}) {
    id
    html
    frontmatter {
      dateCreated(formatString: "MMMM DD, YYYY")
      dateEdited(formatString: "MMMM DD, YYYY")
      path
      title
      description
      series
      tags
      banner {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
          )
        }
      }
    }
    timeToRead
  }
  totalCount: allMarkdownRemark {
    tags: group(field: frontmatter___tags) {
      fieldValue
    }
  }
  site {
    siteMetadata {
      defaultTitle: title
      titleTemplate
      siteUrl
      author
    }
  }
}
`